<template>
  <div class="ExtendedLayoutProductCard mb-8">
    <div class="bg-lighter p-12 desk:p-16 text-14 font-medium">
      <div class="flex justify-between">
        <!-- * * * image, name, isNews -->
        <div class="flex items-center pr-6 leading-[1.4em] basis-4/5">
          <div class="w-40 h-40 mr-12 flex relative">
            <nuxt-img
              v-if="product.image"
              :src="product.image"
              provider="norce"
              preset="standard"
              sizes="40px"
              alt=""
              class="w-full object-cover"
            />
            <img
              v-else
              src="/icons/no-image.svg"
              alt="no image"
              class="w-full object-cover"
            >
            <div class="imageOverlay absolute top-0 left-0 w-full h-full" />
          </div>

          <div class="flex flex-col tabletPortrait:flex-row tabletPortrait:items-center ">
            <div class="leading-single">{{ product.name }}</div>
            <div
              v-if="isNews"
              class="tabletPortrait:ml-4"
            >
              <div
                class="inline-block whitespace-nowrap leading-single p-6 bg-brightGreen text-darkGreen type-headline-xxs"
              >
                {{ $t('productPage.extended.variantNews') }}
              </div>
            </div>
          </div>
        </div>
        <!-- * * * * price, availability -->
        <div class="flex items-center">
          <div v-if="isUpdating" class="w-12 h-12 mr-12 flex items-center">
            <img
              src="/fontawesome/darkest/solid/spinner.svg"
              class="h-full w-full spin inline-block"
              :alt="$t('loading')"
            >
          </div>

          <div class="flex flex-col text-right leading-single">
            <div v-if="userPrice.status === 'logginIn' || userPrice.status === 'pending'" class="priceLoadBox">
              {{ userPrice.price?.priceBeforeVatDisplay.replace(/\d/g, '0') }}
            </div>
            <div
              v-if="userPrice.status === 'notLoggedIn'"
              class="underline cursor-pointer type-xs-medium"
              @click.prevent="showLogin"
            >
              {{ $t('productPage.extended.notLoggedIn') }}
            </div>
            <span v-if="userPrice.status === 'ok'" class="whitespace-nowrap block">
              {{ userPrice.price?.priceBeforeVatDisplay }}
            </span>
            <span
              v-if="(userPrice.price?.isCustomerPrice && userPrice.price?.discountPercentage > 0) || userPrice.price?.isCampaignPrice"
              class="hidden tabletPortrait:block whitespace-nowrap"
            >
              {{ $t('productPage.price.original') }} {{ userPrice.price?.priceBeforeDiscountBeforeVatDisplay }}
              <span v-if="userPrice.price?.isCustomerPrice" class="block !type-xs">
                ({{ $t('productPage.price.yourDiscount') }} <span class="text-brightRed">
                  {{ userPrice.price?.discountPercentage }}%</span>)
              </span>
            </span>
            <StockStatusDesign
              v-if="!uiStore.isMobile || uiStore.isTabletPortrait || stockLabel.type === 'active' || stockLabel.type === 'soldout'"
              class="font-normal mt-2"
              :nowrap="true"
              :color="stockLabel.color"
              :text="stockLabel.textShort"
            />
          </div>
          <div v-if="userStore.isLoggedIn">
            <!-- add to cart or notify -->
            <div class="ml-12 bg-lightest p-4 items-center w-120 text-center">
              <!-- sold out -->
              <div
                v-if="!globalStore.getAllowBackorders && !product.canAddToCart"
                class="h-full w-full text-center"
                @click="showNotifyModal"
              >
                <button class="h-32 ">
                  {{ $t('notify.openModalButton') }}
                </button>
              </div>
              <Tooltip
                v-else-if="userStore.isFinanceUser || userStore.isBlockedCompany"
                :show-on-hover="true"
                :text="userStore.isBlockedCompany ? $t('company.blocked') : $t('priceInfo.financeUserTooltip')"
              >
                <div class="flex items-center">
                  <button
                    class="w-32 h-32 bg-lighter flex items-center"
                    :class="{'pointer-events-none': true}"
                    :disabled="true"
                  >
                    <img
                      class="w-20 m-auto"
                      src="/icons/minus.svg"
                      alt=""
                    >
                  </button>
                  <div class="px-20">{{ quantity }}</div>
                  <button
                    class="w-32 h-32 bg-darkest flex items-center"
                    :class="{'pointer-events-none': true}"
                    :disabled="true"
                  >
                    <img
                      class="w-20 m-auto"
                      src="/icons/plus-inv.svg"
                      alt=""
                    >
                  </button>
                </div>
              </Tooltip>
              <div
                v-else
                class="flex items-center select-none"
                :class="{
                  'pointer-events-none opacity-70': false,
                }"
              >
                <button
                  class="btn btn--minus btn--secondary btn--md basis-32 !h-32 shrink-0"
                  :disabled="quantity === 0"
                  @click="remove"
                />

                <div class="basis-[46px]">{{ quantity }}</div>

                <button
                  class="btn btn--plus btn--md basis-32 !h-32 shrink-0"
                  :disabled="!globalStore.getAllowBackorders && !isInStock"
                  @click="add"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- small screen moved stock and discount -->
      <div class="tabletPortrait:hidden mt-8 text-right pr-[52px]">
        <div
          v-if="(userPrice.price?.isCustomerPrice && userPrice.price?.discountPercentage > 0) || userPrice.price?.isCampaignPrice"
          class="whitespace-nowrap"
        >
          {{ $t('productPage.price.original') }} {{ userPrice.price?.priceBeforeDiscountBeforeVatDisplay }}
          <span v-if="userPrice.price?.isCustomerPrice" class="block type-sm mobOnly:inline-block">
            ({{ $t('productPage.price.yourDiscount') }} <span class="text-brightRed">
              {{ userPrice.price?.discountPercentage }}%</span>)
          </span>
        </div>
        <StockStatusDesign
          v-if="stockLabel.type !== 'active' && stockLabel.type !== 'soldout'"
          class="font-normal justify-end"
          :nowrap="true"
          :color="stockLabel.color"
          :text="stockLabel.textShort"
        />
      </div>
    </div>
    <BackorderNotice :product="product" :is-extended-product-card="true" />
  </div>
</template>

<script lang="ts" setup>
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import { useCartStore } from '~/store/cart';
import { ProductVariantModel } from '~/models/productVariant';
import Tooltip from '~/components/body/Tooltip.vue';
import useStockStatus, { StockLabel } from '~/composeables/useStockStatus';
import StockStatusDesign from '~/components/product/StockStatusDesign.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import BackorderNotice from '~/components/product-page/BackorderNotice.vue';

const uiStore = useUiStore();
const userStore = useUserStore();
const cartStore = useCartStore();
const globalStore = useGlobalContentStore();

const emit = defineEmits<{
  (e: 'updateQty', payload: { product: ProductVariantModel; quantity: number; fromCart: boolean }): void;
}>();

const lastSent = ref(-1);

const quantity = ref(0);

const isInStock = computed(()=> {
  if (props.product.isOnDemandProduct) {
    return true;
  }
  const inStock = props.product.stockStatus?.inStock || 0;
  return inStock >= quantity.value + 1;
});

const props = defineProps<{
  product: ProductVariantModel,
}>();

const showLogin = () => {
  uiStore.setShowSigninModal(true, true);
};
const { getStockLabel } = useStockStatus();
const stockLabel = computed<StockLabel>(() => {
  return getStockLabel(props.product);
});

const showNotifyModal = () => {
  uiStore.notifyModalPartNo = props.product.partNo;
  uiStore.setShowNotify(true);
};

const userPrice = computed(()=> {
  return userStore.getPrice(props.product.partNo);
});

const isUpdating = ref(false);
const add = () => {
  quantity.value++;
  isUpdating.value = true;
  emit('updateQty', { product: props.product, quantity: quantity.value, fromCart: false });
};

const remove = () => {
  quantity.value--;
  isUpdating.value = true;
  emit('updateQty', { product: props.product, quantity: quantity.value, fromCart: false });
};

// On browser reload, wait for cart to load
watch(()=> cartStore.loadingCart, (newVal) => {
  if (newVal === false) {
    setAmountFromCart();
  }
});

watch(() => cartStore.isLoading, (newVal) => {
  if (newVal === false) {
    setAmountFromCart();
  }
});

onMounted(()=> {
  setAmountFromCart();
});

const setAmountFromCart = () => {
  const cartQty = cartStore.getQuantityFromPartNo(props.product.partNo);
  if (quantity.value === cartQty) {
    isUpdating.value = false;
  }

  if (!isUpdating.value) {
    quantity.value = cartQty;
  }

  lastSent.value = quantity.value;
};

const isNews = computed(()=> {
  return props.product.flags.some((s) => s.code === 'news-variant');
});

// Listen to changes from minicart
watch(()=> cartStore.getQuantityFromPartNo(props.product.partNo), (newVal) => {
  if (newVal !== quantity.value) {
    emit('updateQty', { product: props.product, quantity: newVal, fromCart: true });
  }
});
</script>

<style scoped lang="postcss">
.imageOverlay {
  background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF00 0%, #0000000A 100%) 0% 0% no-repeat padding-box;
}
</style>
