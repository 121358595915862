<template>
  <div
    v-if="productBackorderNotices.length && backorderNoticeTotalQuantity > 0"
    class="BackorderNotice bg-brightYellow type-xs-medium"
    :class="{
      'px-16 py-6 pt-8': isExtendedProductCard,
      'p-16': !isExtendedProductCard,
    }"
  >
    <span v-if="productBackorderNotices[0].estimatedDeliveryDate !== ''">
      {{ $t('product.backorderNoticeWithDate', {
        quantity: backorderNoticeTotalQuantity,
        date: productBackorderNotices[0].estimatedDeliveryDate
      }) }}
    </span>
    <span v-else>
      {{ $t('product.backorderNotice', { quantity: backorderNoticeTotalQuantity }) }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '~/store/user';
import { ProductVariantModel } from '~/models/productVariant';

const userStore = useUserStore();

const props = defineProps<{
  product: ProductVariantModel,
  isExtendedProductCard: boolean,
}>();

const productBackorderNotices = computed(() => {
  return userStore.backorderNotices.filter((i) => i.productNo === props.product.partNo);
});

const backorderNoticeTotalQuantity = computed(() => {
  return productBackorderNotices.value?.map((i) => i.quantity)?.reduce((a, c) => a + c, 0) ?? 0;
});
</script>
